body{
  background-color:lightgrey;
}

.App {
  text-align: center;
  font-family: 'League Spartan', sans-serif;
  font-family: 'Playfair Display', serif;
  font-size: large;
  font-weight: 800;
  color: maroon;
  margin-top: 10%;
  border: 1px solid maroon;
  padding-bottom: 2rem;
  margin-left: 30%;
  margin-right: 30%;
}

.message-box{
 display: flex;
 justify-content: center;
 margin-top: 1rem; 
}

.delete-button{
  margin-left: .5rem;
  padding: 0 6px;
}

.message-container{
  margin-bottom: .5rem;
}

.latest-btn{
  margin-top: 1rem;
}